import React, { CSSProperties } from "react";
import { ParallaxBanner } from "react-scroll-parallax";

import { generator } from "../gradients";
import styled from "../../theme/styled";
import { IGradients } from "../gradients/gradient-list";

interface IPanel {
  className?: string;
  gradient?: keyof IGradients;
  backgroundImage?: string;
  backgroundColor?: string;
  borderTop?: string;
  parallax?: boolean;
  maxHeight?: number;
  children: React.ReactNode;
  style?: CSSProperties;
}

interface IPanelContainer {
  gradient?: keyof IGradients;
  maxHeight?: number;
  backgroundColor?: string;
  borderTop?: string;
  backgroundImage?: string;
}

const PanelContainer = styled<IPanelContainer, "div">("div")`
  /* display: flex; */
  position: relative;
  /* padding: 0 100px; */
  /* border-top: ${props =>
    props.borderTop ? `2px solid ${props.borderTop}` : ""}; */
  background: ${props => props.backgroundColor};
  background: ${props =>
    props.backgroundImage && `url(${props.backgroundImage})`};
  background: ${props =>
    props.gradient && generator({
      gradient: props.gradient,
      angle: -38
    })};
  /* z-index: 0; */
  /* width: 100vw; */
  /* height: 100vh; */
  /* min-height: ${props => (props.maxHeight ? props.maxHeight - 200 : 600)}px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : 750)}px; */

  > section {
    /* width: 100%; */
  }

  @media (max-width: 1100px) {
    /* padding: 40px; */
  }

  @media (max-width: 768px) {
    /* padding: 20px;
    height: auto;
    min-height: none;
    max-height: none; */
  }
`;

const ParallaxPanelContainer = styled(ParallaxBanner)`
  height: auto !important;
`;

const PanelContent = styled("div")`
  /* max-width: 1200px; */
  /* margin: 0 auto; */
`;

const Panel = (props: IPanel) => {
  if (!props.parallax) {
    return (
      <PanelContainer
        className={props.className}
        style={props.style}
        gradient={props.gradient}
        backgroundImage={props.backgroundImage}
        backgroundColor={props.backgroundColor}
        borderTop={props.borderTop}
        maxHeight={props.maxHeight}
      >
        <PanelContent>
          {props.children}
        </PanelContent>
      </PanelContainer>
    );
  } else {
    return (
      <ParallaxPanelContainer
        className={props.className}
        layers={[
          {
            image: props.backgroundImage || "",
            amount: 0.1,
            children: null
          }
        ]}
        style={{ ...props.style }}
      >
        <PanelContent>
          {props.children}
        </PanelContent>
      </ParallaxPanelContainer>
    );
  }
};

export default Panel;
