import * as React from "react";

import styled, { css } from "../../theme/styled";
import { IThemeColour } from "../../theme/theme-definition";
import {
  colourHelper,
  fontSizeHelper,
  fontWeightHelper,
  fontLineHeightHelper,
  fontSpaceHelper
} from "../../theme/theme-helper";

interface IProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    React.HTMLAttributes<HTMLParagraphElement> {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  color?: keyof IThemeColour;
}

interface IHeadingStyled {
  color?: IProps["color"];
}

/**
 * How you benefit
 */
const HeadingUppercase: React.FC<IProps> = ({
  as, children, ...rest
}) => {
  switch (as) {
    case "h1":
      return (
        <H1Styled {...rest}>
          {children}
        </H1Styled>
      );

    case "h2":
      return (
        <H2Styled {...rest}>
          {children}
        </H2Styled>
      );

    case "h3":
      return (
        <H3Styled {...rest}>
          {children}
        </H3Styled>
      );

    case "h4":
      return (
        <H4Styled {...rest}>
          {children}
        </H4Styled>
      );

    case "h5":
      return (
        <H5Styled {...rest}>
          {children}
        </H5Styled>
      );

    case "h6":
      return (
        <H6Styled {...rest}>
          {children}
        </H6Styled>
      );

    case "p":
      return (
        <PStyled {...rest}>
          {children}
        </PStyled>
      );

    default:
      return null;
  }
};

const headingStyling = css<IHeadingStyled>`
  ${({ theme, color }) => css`
    color: ${colourHelper({
    theme,
    variant: color || "black"
  })}
    font-size: ${fontSizeHelper({
    theme,
    variant: "small_3"
  })};
    font-weight: ${fontWeightHelper({
    theme,
    variant: "regular"
  })};
    line-height: ${fontLineHeightHelper({
    theme,
    variant: "default"
  })};
    letter-spacing: ${fontSpaceHelper({
    theme,
    variant: "medium"
  })};
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
  `}
`;

const H1Styled = styled.h1<IHeadingStyled>`
  ${headingStyling}
`;

const H2Styled = styled.h2`
  ${headingStyling}
`;

const H3Styled = styled.h3<IHeadingStyled>`
  ${headingStyling}
`;

const H4Styled = styled.h4<IHeadingStyled>`
  ${headingStyling}
`;

const H5Styled = styled.h5<IHeadingStyled>`
  ${headingStyling}
`;

const H6Styled = styled.h6<IHeadingStyled>`
  ${headingStyling}
`;

const PStyled = styled.p<IHeadingStyled>`
  ${headingStyling}
`;

export default HeadingUppercase;
