import React from "react";

import WhyGravitywellImg from "src/assets/images/mvp/why-gravitywell-meeting.jpg";
import styled, { css } from "src/theme/styled";

import { MediumColumnWidthWrapper } from "../confines-wrappers";

interface WhyGravitywellStartupsProps {
  /** array-item = paragraph */
  text?: string | string[];
}

const WhyGravitywellStartups: React.FC<WhyGravitywellStartupsProps> = ({ text }) => {
  return (
    <StyledWhyGravitywellSection
      id="why-gravitywell-section"
      className="why-gravitywell-section content-container"
    >
      <MediumColumnWidthWrapper className="why-gravitywell-section-wrapper">
        <div className="copy">
          <h4 role="heading">
            Why Gravitywell?
          </h4>

          {text && (
            Array.isArray(text) ? (
              text.map((paragraph, index) => (
                <p key={index}>
                  {paragraph}
                </p>
              ))
            ) : (
              <p>
                {text}
              </p>
            )
          ) || (
            <>
              <p>
                We're a close knit, multidisciplinary team based in the heart of Bristol, home to some of the UK's most exciting, dynamic startups.
              </p>

              <p>
                Every piece of work we commit to is completed in-house.
              </p>

              <p>
                We develop long-term, successful partnerships with our clients, who share our innovation-focused approach.
              </p>

              <p>
                Collaboration is at the heart of all that we do.
              </p>
            </>
          )}
        </div>

        <div className="image">
          <img
            className="full"
            src={WhyGravitywellImg}
            alt="Gravitywell having a internal meeting about an upcoming project"
          />
        </div>
      </MediumColumnWidthWrapper>
    </StyledWhyGravitywellSection>
  );
};

const StyledWhyGravitywellSection = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.altLightGrey};

    .why-gravitywell-section-wrapper {
      display: flex;
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
      }

      .copy {
        z-index: 2;
        padding-top: 4.2rem;

        @media (min-width: 768px) {
          padding-top: 0;
          width: 100%;
          max-width: 45.2rem;
        }

        h2 {
          font-size: ${theme.font.size.subhead_3};

          @media (min-width: 960px) {
            font-size: 3rem;
          }
        }

        p {
          font-size: ${theme.font.size.body_2};
        }
      }

      .image {
        max-width: 60rem;
        transition: all 0.2s ease-in-out;

        @media (min-width: 768px) {
          margin-right: 5%;
        }

        @media (min-width: 1024px) {
          margin-right: 6%;
        }

        @media (min-width: 1280px) {
          margin-right: 8%;
        }

        @media (min-width: 1380px) {
          margin-right: 10%;
        }
      }
    }
  `};
`;

export default WhyGravitywellStartups;