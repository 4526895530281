import * as React from "react";

import styled from "../../theme/styled";
import { IThemeColour } from "../../theme/theme-definition";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import DynamicHtmlTag from "../dynamic-html-tag";

export type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface IProps {
  className?: string;
  headingTag: HeadingTag;
  title: string;
  textColor?: keyof IThemeColour;
  hrColor?: keyof IThemeColour;
}

interface IHeadingWrapper {
  textColor: keyof IThemeColour;
  hrColor: keyof IThemeColour;
}

const HeadingWrapper = styled<IHeadingWrapper, any>(DynamicHtmlTag)`
  transition: 0.2s ease-in-out;
  margin: 0;
  color: ${({ theme, textColor }) =>
    colourHelper({
      theme,
      variant: textColor
    })};
  font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
  font-weight: ${({ theme }) => fontWeightHelper({
    theme,
    variant: "heavy"
  })};
  line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
  text-align: center;

  .text {
    display: block;
  }

  .underline {
    display: block;
    margin: 2.2rem auto 0;
    width: 5rem;
    height: 0.5rem;
    background-color: ${({ theme, hrColor }) =>
    colourHelper({
      theme,
      variant: hrColor
    })};
  }

  @media (min-width: 600px) {
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "title"
  })};
  }

  @media (min-width: 960px) {
    font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
  }
`;

const HeadingUnderlined = (props: IProps) => (
  <HeadingWrapper
    className={props.className}
    htmlTag={props.headingTag}
    textColor={props.textColor || "black"}
    hrColor={props.hrColor || "brandColor"}
  >
    <>
      <span className="text">
        {props.title}
      </span>

      <span className="underline" />
    </>
  </HeadingWrapper>
);

export default HeadingUnderlined;
