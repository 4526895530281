import * as React from "react";

import TestimonialCarousel from "../testimonial-carousel";

const testimonials = [
  {
    company: "dubbii & ADHD Love",
    name: "Rich & Rox",
    role: "Founders",
    testimonial:
      "Since our first meeting with Gravitywell, it was clear they were an exceptional and highly creative team! They became massive advocates for the ADHD brain during the app development process and we couldn't have hoped for a better partner to build dubbii with, going above and beyond every single time."
  }
];

const Testimonial: React.FC = () => (
  <>
    <h2 className="off-screen">
      Testimonials
    </h2>

    <TestimonialCarousel
      content={testimonials}
      backgroundColor="altLightGrey"
    />
  </>
);

export default Testimonial;
