import React from "react";

import styled, { css } from "src/theme/styled";
import {
  colourHelper,
  fontSizeHelper,
  fontWeightHelper,
  spacingHelper
} from "src/theme/theme-helper";

import { MediumColumnWidthWrapper } from "../confines-wrappers";
import { HeadingUppercase } from "../heading";
import Link from "../link";
import Panel from "../panel-wrapper";

interface CaseStudyPanelProps {
  desktopLayout: "left" | "right";
  label: string;
  heading: string;
  keyPoints?: string[];
  backgroundImage: string;
  linkUrl?: string;
  linkCta?: string;
}

const CaseStudyPanel: React.FC<CaseStudyPanelProps> = props => {
  const {
    desktopLayout,
    label,
    heading,
    keyPoints,
    backgroundImage,
    linkUrl,
    linkCta
  } = props;

  return (
    <CaseStudyPanelContainer desktopLayout={desktopLayout}>
      <Panel
        backgroundImage={backgroundImage}
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative"
        }}
      >
        <MediumColumnWidthWrapper className="content-container">
          <div
            className="flex-wrapper"
          >
            <div className="titles-wrapper">
              <HeadingUppercase
                as="h2"
                color="white"
              >
                {label}
              </HeadingUppercase>

              <p className="large-copy">
                {heading}
              </p>

            </div>

            {keyPoints?.length && (
              <div
                className="list-container"
              >
                {keyPoints.length > 1 ? (
                  <ul className="list">
                    {keyPoints.map(str => (
                      <li
                        key={str}
                      >
                        {str}
                      </li>
                    ))}
                  </ul>
                ) : (
                  // single item
                  <ul
                    className="list"
                    style={{ paddingInline: 0 }}
                  >
                    <li
                      style={{
                        listStyle: "none",
                        lineHeight: "1.5"
                      }}
                    >
                      {keyPoints[ 0 ]}
                    </li>
                  </ul>
                )}
              </div>
            )}

            {(linkUrl && linkCta) && (
              <div className="section-cta-container">
                <p>
                  <span className="off-screen">
                    Click here to
                    {" "}
                  </span>

                  <Link
                    type="styled"
                    color="brandColor"
                    href={linkUrl}
                  >
                    {linkCta}
                  </Link>
                </p>
              </div>
            )}
          </div>
        </MediumColumnWidthWrapper>
      </Panel>
    </CaseStudyPanelContainer>
  );
};

const CaseStudyPanelContainer = styled.div<{desktopLayout: "left" | "right"}>`
${({ theme }) => css`
    color: ${colourHelper({
    theme,
    variant: "white"
  })}

/* *:not([style*="background-size"]) {
	z-index: 1;
	outline: 2px solid red;
} */

.content-container {
  min-height: 50rem;
  @media (min-width: 840px) {
    ${({ desktopLayout }) => desktopLayout === "right" && css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
  }
	/* overlaying black on bg image */
	/* giving other children higher stacking index */
	& > * {
		z-index: 1;
		position: relative;
}
	&::after {
		z-index: 0 !important;
		content: "";
		position: absolute !important;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #141023;
		opacity: 0.66;
		mix-blend-mode: hard-light;
		pointer-events: none;
	}
}

	.large-copy {
		padding-top: 4%;
		font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
					font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_1"
  })};
	}

	.section-cta-container {
    width: 100%;
		padding-top: ${spacingHelper({
    theme,
    variant: "xl"
  })};
		padding-bottom: clamp(2em, 5vmin, 200px);
		text-align: center;

    p {
      font-size: ${fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      font-weight: ${fontWeightHelper({
    theme,
    variant: "bold"
  })};

a {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        border-bottom-color: rgba(255, 255, 255, 0.4);

        &:hover {
          border-bottom-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

	@media (min-width: 375px) {
		.titles-wrapper {
			.large-copy {
				font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
			}
		}
	}

	@media (min-width: 480px) {
      .titles-wrapper {
        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
        }
      }
    }

    @media (min-width: 568px) {
      .titles-wrapper {
				.large-copy {
					max-width: 25ch;
          font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_3"
  })};
        }
      }
    }

		@media (min-width: 600px) {
      .titles-wrapper {
        .large-copy {
          padding-top: 1.8rem;
          font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
        }
      }

			.section-cta-container {
				text-align: unset;
				padding-bottom: unset;
			}
    }

		@media (min-width: 768px) {
      .titles-wrapper {
        .large-copy {
          font-size: ${fontSizeHelper({
    theme,
    variant: "title"
  })};
        }
      }
    }

    @media (min-width: 960px) {
      .titles-wrapper {
        .large-copy {
          padding-right: 0;
        }
      }

			.section-cta-container {
				padding-top: ${spacingHelper({
    theme,
    variant: "xxl"
  })};
			}
    }

    @media (min-width: 1024px) {
      .titles-wrapper {
        padding-right: 0;
        max-width: 93rem;

        .large-copy {
          padding-right: 0;
        }
      }
    }

		@media (min-width: 1440px) {
			.section-cta-container {
					padding-top: calc(${spacingHelper({
    theme,
    variant: "xl"
  })} * 2);
    }
		}
  
`} /* end theme */
`;

export default CaseStudyPanel;